/* MOBILE VIEW */
@media (max-width: 575.98px) {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #3e4444;
  }

  .container-fluid {
    width: 100%;
    color: #ffffffee;
    background-color: #3e4444;
    margin: auto;
    align-self: center;
  }
  #session-clock {
    font-size: 4rem;
    margin-top: 25px;
    border: 5px #ffffffd8 solid;
    border-radius: 10px;
  }
  .in-session {
    background-color: #82b74b;
  }
  .on-break {
    background-color: #c94c4c;
  }
  .off {
    background-color: #55acee;
  }
  .blinking {
    animation: blinkingText 3s infinite;
  }
  @keyframes blinkingText {
    0% {
      color: #ffffffd8;
    }
    50% {
      color: transparent;
    }
    100% {
      color: #ffffffd8;
    }
  }

  #session-length-text,
  #break-length-text {
    font-size: 1.2rem;
    display: inline;
    margin-right: 10px;
  }

  #length-container {
    width: 100%;
    margin: 25px auto;
    padding: 20px 0 0 0;
    border-top: 2px #ffffffd8 solid;
  }

  button {
    background-color: transparent;
    border: none;
    color: #ffffffd8;
    padding: 2px;
  }
  .hidden {
    display: none;
  }
}

/* TABLET VIEW */

@media (min-width: 575.98px) and (max-width: 767.98px) {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .container-fluid {
    width: 100%;
    color: #ffffffee;
    background-color: #3e4444;
    margin: auto;
    align-self: center;
  }
  #clock-title {
    padding: 10px;
    margin: 10px;
  }
  #sub-container {
    border: 5px white solid;
    box-shadow: 6px 7px 10px black;
    color: #ffffffee;
    background-color: #3e4444;
  }

  #session-clock {
    font-size: 4rem;
    margin-top: 20px;
    border: 5px #ffffffd8 solid;
    border-radius: 10px;
  }
  .in-session,
  .container-in-session {
    background-color: #82b74b;
  }
  .on-break,
  .container-on-break {
    background-color: #c94c4c;
  }
  .off,
  .container-off {
    background-color: #55acee;
  }
  .blinking {
    animation: blinkingText 3s infinite;
  }
  @keyframes blinkingText {
    0% {
      color: #ffffffd8;
    }
    50% {
      color: transparent;
    }
    100% {
      color: #ffffffd8;
    }
  }

  #session-length-text,
  #break-length-text {
    display: inline;
    margin: 0 10px 0 0;
    font-size: 1.2rem;
  }

  #length-container-col {
    margin: 50px 0;
    padding: 20px 0 0 0;
    border-top: 2px #ffffffd8 solid;
  }

  button {
    background-color: transparent;
    border: none;
    color: #ffffffd8;
    padding: 2px;
  }
  .hidden {
    display: none;
  }
}

/* DESKTOP VIEW */

@media (min-width: 767.98px) {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .container-fluid {
    width: 100%;
    color: #ffffffee;
    background-color: #3e4444;
    margin: auto;
    align-self: center;
  }
  #clock-title {
    padding: 10px;
    margin: 10px;
  }
  #sub-container {
    border: 5px white solid;
    box-shadow: 6px 7px 10px black;
    color: #ffffffee;
    background-color: #3e4444;
  }

  #session-clock {
    font-size: 4rem;
    margin-top: 20px;
    border: 5px #ffffffd8 solid;
    border-radius: 10px;
  }
  .in-session,
  .container-in-session {
    background-color: #82b74b;
  }
  .on-break,
  .container-on-break {
    background-color: #c94c4c;
  }
  .off,
  .container-off {
    background-color: #55acee;
  }
  .blinking {
    animation: blinkingText 3s infinite;
  }
  @keyframes blinkingText {
    0% {
      color: #ffffffd8;
    }
    50% {
      color: transparent;
    }
    100% {
      color: #ffffffd8;
    }
  }

  #session-length-text,
  #break-length-text {
    display: inline;
    margin-right: 10px;
    font-size: 1.2rem;
  }

  #length-container-col {
    margin: 50px auto;
    padding: 20px 0 0 0;
    border-top: 2px #ffffffd8 solid;
  }

  button {
    background-color: transparent;
    border: none;
    color: #ffffffd8;
    padding: 2px;
  }

  .hidden {
    display: none;
  }
}
